export var Locale = {
    De: "de",
    En: "en",
    Fr: "fr"
};
export var ContentHubQuery = "ContentHubQuery:05e7ece6c685b48b59bb8d62eee8bcfcbfd4d28bbf16ffbb8453a4e270ce9ca3";
export var FrameQuery = "FrameQuery:6a0a4834a04b33af41189d5ec9d180deaa33265476c954278dbc929f85852c88";
export var HomePageQuery = "HomePageQuery:6c71af02956a427af570638b629185a4ac3bb807590e48a91d9577bbe845d4b8";
export var ListPagesQuery = "ListPagesQuery:1f7e06f1abff052f072012075876848f4df447641e84c1cb9ab5809ae923108a";
export var NotFoundPageQuery = "NotFoundPageQuery:5ced669b42090ea1058482a491c74416e647e746d2d66022afc3e1c19f78efdb";
export var PreviewDecapPageQuery = "PreviewDecapPageQuery:04a36a5ac125fe41ba51475429ae563ed7adc7fa1c42aeeb8a190c80039a4b50";
export var PreviewDrupalPageQuery = "PreviewDrupalPageQuery:9287d3165e7603e2fe711ea7859e10b8cc2860a7391e73bd15595acece3f6729";
export var ViewPageQuery = "ViewPageQuery:7034f9ff6896d177f8bf612ba41c134ae3ac0662f2fd4193891bcad6d561fb9e";
